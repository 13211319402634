<!--站点页面-->
<template>
  <div id="site">
    <transition name="slide-fade" mode="out-in">
      <div class="site" key="replace">
          <div class="row" v-for="(items, values) in data" :key="values">
            <component
              v-for="(item, value) in items"
              :key="item.id + item.siteServiceTypeENname"
              :idCode="values * num + value"
              :is="item.siteServiceTypeENname"
              :data="item"
            >
            </component>
            <div class="empty" v-for="empty in (4 - items.length)" :key="empty"></div>
          </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ACS from "./components/door"; //1.门禁
import FACILITIES from "./components/equipment"; //2.通用设备管理
import WATER from "./components/waterEnergy"; //3.水能
import ELECTRICITY from "./components/electricityEnergy"; //4.电能
import MONITORING from "./components/monitoring"; //5.视频监控 *
import ELEVATOR from "./components/elevator"; //6.电梯 *
import CONSUME from "./components/consume"; //7.消费 *
import IOW from "./components/passageway"; //8.出入口 *
import VISITOR from "./components/visitorS"; //9.访客 yuan
import ATTENDANCE from "./components/attendance"; //10.考勤
import MEETING from "./components/conference"; //11.会议 yuan
import LIGHTING from "./components/lighting"; //12.照明
import ENVIRONMENT from "./components/environment"; //13.环境
import OTRAC from "./components/otrac"; //14.其他空调
import VRFAC from "./components/vrfac"; //15.多联机空调
import BSDSAC from "./components/bsdsac"; //16.水冷空调
import ELE_FAC  from "./components/eleFacility";//17.电梯客户端RMCS
import END_FAC  from "./components/edgeEnd";//18.边缘终端
import INFO_FAC  from "./components/informationDistribution";//22.信息发布
import CAR_SPEED from "./components/velocityMeasurement" //23.道路测速

export default {
  data() {
    return {
      num: 4,
      show: true
    }
  },
  mounted(){
    localStorage.removeItem('serviceType')
    localStorage.removeItem('facilityBuildName')
  },
  computed: {
    data() {
      let list = this.$store.getters.threeMenu
      let num = this.num
      let length = Math.ceil(list.length / num)
      let data = []
      for (let i = 0; i < length; i++) {
        data.push(list.slice(i * num, i * num + num))
      }
      return data
    }
  },
  components: {
    ACS, //门禁
    FACILITIES, //通用设备管理
    WATER, //水能
    ELECTRICITY, //电能
    MONITORING, //视频监控
    ELEVATOR, //电能
    CONSUME, //消费
    IOW, //出入口
    VISITOR, //访客
    ATTENDANCE, //考勤
    MEETING, //会议
    LIGHTING, //照明
    ENVIRONMENT, //环境
    OTRAC, //其他空调
    VRFAC, //多联机空调
    BSDSAC, //水冷空调
    END_FAC,//边缘终端
    INFO_FAC,//信息发布
    ELE_FAC,//电梯管理
    CAR_SPEED //道路测速
  },
};
</script>

<style scoped>
#site {
  width: calc(100% - 40px);
  min-width: 1024px;
  position: absolute;
}
.site{
  position: relative;
}
.row {
  display: flex;
  justify-content: space-between;
}
.empty{
  width: 3rem;
  height: 2.5rem;
}
.elevator {
  margin-top: 0.2rem;
}
.slide-fade-enter-active {
  transition: all 0.6s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>