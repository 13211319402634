<!--站点卡片--其他空调卡片-->
<template>
  <div id="door"  :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/airconditioner.png" />
      <span>{{data.name}}</span>
    </div>
    <div class="main">其他空调卡片</div>
  </div>
</template>

<script>
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    get() {
      localStorage.setItem('serviceType', 1);
      console.log("obj", this.data);
    },
  },
};
</script>

<style scoped>
#door {
  width: 400px;
  height: 310px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#door:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  padding-bottom: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.header img {
  height: 30px;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 250px;
  background-color: #f2f4f7;
}
</style>