<!--站点卡片--访客卡片-->
<template>
  <div id="visitorS" @click="goIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/visitor.png" />
      <!-- <span>{{ data.name }}</span> -->
      <span>{{ data.name }}</span>
    </div>
    <div class="main" v-loading="loading">
      <h3 class="data-title">{{ $t("sitecard.a9") }}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getVisitorOrderList,getVisitorReportData2,getVisitorReportData } from "../../../api/visitor";
import moment from "moment";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  name: "visitor",
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading:false,
      list:[],
      option: {
        color:colors,
        legend: {
          orient: "horizontal",
          left: 10,
          data:[],
          // data: ["运行数", "待机数", "故障数", "总数"],
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        series: [
          {
            type: "pie",
            top: "30",
            radius: "70%",
            center: ["50%", "50%"],
            data: [
              
            ].sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: "radius",
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      },
    };
  },
  computed: {
    echartsId() {
      return "echarts-visitor-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  mounted() {
    this.getStatisticalData();
    // this.drawLine();
  },
  methods: {
    getStatisticalData() {
      this.loading=true;
      let siteId = this.data.id;
      let par = {
        dateTimeBegin: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        dateTimeEnd: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        groupbyStrs: [],        
        reportTypeStr: "2",
        siteId: siteId,
        tenantId: 0,
        visitorCompany: "",
        visitorName: "",
        visitorPhone: "",
        visitorType: "",
        personCode: "",
        personDept: "",
        personName: "",
        personPhone: "",
    };          
    // console.log("getVisitorReportData par:",par);
    getVisitorReportData(par)
      .then((res) => {              
        this.loading = false;
        let { data } = res;
        if(data!=null&&data.length>0){
          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
          }
          this.statisticsData(data);
        }
        // console.log("getVisitorReportData res:",this.list);
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    statisticsData(data){
      if(data!=null&&data.length>0){
        let notVisitSum = 0;//待访数
        let inVisitSum = 0;//在访数
        let outVisitSum = 0;//离开数
        let finVisitSum = 0;//完成数
        let stVisitSum = 0;//滞留数
        let cnlVisitSum = 0;//取消数
        let misVisitSum = 0;//失约数
        for (let i = 0; i < data.length; i++) {
          notVisitSum+=data[i].notVisitSum;
          inVisitSum+=data[i].inVisitSum;
          outVisitSum+=data[i].outVisitSum;
          finVisitSum+=data[i].finVisitSum;
          stVisitSum+=data[i].stVisitSum;
          cnlVisitSum+=data[i].cnlVisitSum;
          misVisitSum+=data[i].misVisitSum;
        }
        this.list.push({value:notVisitSum,name:this.$t("visitorStatisticalReport.a14")});
        this.list.push({value:inVisitSum,name:this.$t("visitorStatisticalReport.a15")});
        this.list.push({value:outVisitSum,name:this.$t("visitorStatisticalReport.a16")});
        this.list.push({value:finVisitSum,name:this.$t("visitorStatisticalReport.a17")});
        this.list.push({value:stVisitSum,name:this.$t("visitorStatisticalReport.a18")});
        this.list.push({value:cnlVisitSum,name:this.$t("visitorStatisticalReport.a19")});
        this.list.push({value:misVisitSum,name:this.$t("visitorStatisticalReport.a20")});

        this.option.series[0].data = this.list;
        this.drawLine();
      }
    },    
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option, true);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
    goIndex() {
      localStorage.setItem('serviceType', 12);
      this.$router.push({
        path: "/homepage/visitor/equipment",
        query: { id: this.data.id, Code: this.idCode,serviceType:12 },
      });
    },
  },
};
</script>

<style scoped>
#visitorS {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#visitorS:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>