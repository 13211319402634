<!--站点卡片--出入口卡片-->
<template>
  <div id="attendance" @click="goIndex()"  :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/checkingIn.png" />
      <span>{{ data.name }}</span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <h3 class="data-title">{{$t("sitecard.a10")}}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getDoorCardData,getHistoryStatisticsData } from "../../../api/door";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      echarts1_option: {
        color:colors,
        //color: ["#5470c6", "#91cc75", "#fac858", "#ee6666"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            //roseType: "radius",
            data: [],
          },
        ],
      },
      option3: {
        color: colors,
        // backgroundColor:'rgba(12,90,150,0.1)',
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
              let len = params.length;
              let val = 0;
              params.forEach(element => {
                val+=element.value;
              });
              let rates = [];
              if(val>0){
                params.forEach(element => {
                  rates.push(element.value/val* 100);
                });
              }else{
                params.forEach(element => {
                  rates.push(0);
                });
              }
              let name = params[0].name;
              let content = name;
              let i=0;
              params.forEach(element => {
                  content+= ' <br/>'+element.seriesName+' : '+element.value+' ('+rates[i].toFixed(2)+'%)';
                  i++;
              });

              return content;
            },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: { },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: "25%",
          containLabel: true
        },
        xAxis: [ { type: 'value' }],
        yAxis: [ ],
        series: []
      },
    };
  },
  computed: {
    echartsId() {
      return "echarts-att-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  mounted() {
    // this.getStatisticalData();
    this.getHistoryStatistics();
    
  },
  methods: {
    goIndex() {
       localStorage.setItem('serviceType', 13);
      this.$router.push({
        path: "/homepage/attendance/equipment",
        query: { id: this.data.id, Code: this.idCode,serviceType:13  },
      });
    },
    getHistoryStatistics(){
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      getHistoryStatisticsData({
        clientId,
        siteId,
        pageSize:10,
        serviceType:11,
        // group:"EquipmentName",
        group:"Date",
        star:"2023-05-18 00:00:00",
        end:"2023-05-31 23:59:59"
      })
        .then((res) => {
          this.loading = false;
          // console.log("res>>>",res);
          let { data } = res;
          this.option3.series = data.series;
          this.option3.legend = data.legend;
          this.option3.legend.left ='right';
          this.option3.legend.top ='10%';
          this.option3.yAxis  = data.yAxis;
          this.option3.yAxis[0].axisLabel={ show:true, interval:0, };

          this.echarts1_option = this.option3;
          this.drawLine();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 获取卡片显示的内容数据
    getStatisticalData() {
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      getDoorCardData({
        clientId,
        siteId,
      })
        .then((res) => {
          this.loading = false;
          // console.log("res>>>",res);
          let { data } = res;
          this.echarts1_option.series[0].data = data;
          this.drawLine();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 根据数据加载图表
    drawLine() {
      // let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      // myChart.setOption(this.echarts1_option);
        let myChart = this.$echarts.init(document.getElementById(this.echartsId));
        let resizeDiv = document.getElementById(this.echartsId);
        myChart.setOption(this.echarts1_option);
        let listener = () => {
          myChart.resize();
        };
        EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#attendance {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#attendance:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  display: flex;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>