<!--站点卡片--照明卡片-->
<template>
  <div id="lighting" @click="goLightingIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/light.png" />
      <span>{{ data.name }}</span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <h3 class="data-title">{{ $t("sitecard.a7") }}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getDeviceData } from "../../../api/device";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      option: {
        color:colors,
        // backgroundColor: "#2c343c",
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        series: [
          {
            name: this.data.siteServiceTypeName,//"访问来源",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: [
              { value: 7, name: "运行数" },
              { value: 9, name: "待机数" },
              { value: 7, name: "故障数" },
              { value: 13, name: "总数" },
            ].sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: "radius",
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      },

      //   echarts1_option: {
      //     legend: {
      //       orient: "horizontal",
      //       left: 10,
      //       data: [
      //         "startIn",
      //         "startOut",
      //         "stopIn",
      //         "stopOut",
      //       ],
      //     },
      //     series: [
      //       {
      //         top: 20,
      //         name: "访问来源",
      //         type: "pie",
      //         radius: ["35%", "50%"],
      //         label: {
      //           formatter: "{b|{b}:{c}}\n {per|{d}%}",
      //           backgroundColor: "#eee",
      //           borderColor: "#aaa",
      //           borderWidth: 1,
      //           borderRadius: 4,
      //           rich: {
      //             b: {
      //               fontSize: 12,
      //               lineHeight: 25,
      //               padding: [0, 10, 0, 10],
      //             },
      //             per: {
      //               color: "#eee",
      //               align: "center",
      //               backgroundColor: "#7682ce",
      //               lineHeight: 25,
      //               padding: [3, 4],
      //               borderRadius: 2,
      //             },
      //           },
      //         },
      //         data: [],
      //       },
      //     ],
      //   },
    };
  },
  computed: {
    echartsId() {
      return "echarts-light-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  watch: {
    // echarts1_option: {
    option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.myChart.setOption(newVal);
          } else {
            this.myChart.setOption(oldVal);
          }
        } else {
          this.drawLine();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getStatisticalData();
    this.drawLine();
  },
  methods: {
    // goLightingIndex() {
    //   this.$router.push({
    //     path: "/homepage/light/equipment",
    //     query: { id: this.data.id },
    //   });
    // },
    // 跳转卡片对应主页
    goLightingIndex() {
      // localStorage.setItem('serviceType', 1);//li_yj on 2022-9-30警报查询
      localStorage.setItem('serviceType', 2);
      // console.log("goLightingIndex siteId:", this.data.id);
      this.$router.push({
        path: "/homepage/light/equipment",
        query: { id: this.data.id, Code: this.idCode },
      });
    },
    getStatisticalData() {
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      let siteServiceTypeId = this.data.siteServiceTypeID;
      // console.log(
      //   "lighting par:",
      //   "clientId:" +
      //     clientId +
      //     ",siteId:" +
      //     siteId +
      //     ",siteServiceTypeID:" +
      //     siteServiceTypeId
      // );
      getDeviceData({
        clientId,
        siteId,
        siteServiceTypeId,
      })
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.option.series[0].data = data;
          // console.log(this.data.name + "  Light data:", data);
        })
        .catch((err) => {
          this.loading = false;
          console.log("错了", err);
        });
    },
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      // console.log("echartsId:", this.echartsId);
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#lighting {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#lighting:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>