<!--站点卡片--环境卡片-->
<template>
  <div id="environment" @click="goEnvironmentIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/environment.png" />
      <span>{{ data.name }}</span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <h3 class="data-title">{{ $t("sitecard.a6") }}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from "../../../api/api";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      echarts1_option: {
        color:colors,
        // title: {
        //     text: '南丁格尔玫瑰图',
        //     subtext: '纯属虚构',
        //     left: 'center'
        // },
        tooltip: {
          trigger: "item",
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: "center",
          top: "bottom",
          data: [],
          // data: ["rose1", "rose2", "rose3", "rose4"],
        },

        series: [
          {
            // name: '',
            type: "pie",
            radius: "50%",
            center: ["50%", "50%"],
            roseType: "radius",
            data: [
              { value: 10, name: "rose1" },
              { value: 5, name: "rose2" },
              { value: 15, name: "rose3" },
              { value: 25, name: "rose4" },
            ],
          },
        ],
      },
    };
  },
  computed: {
    echartsId() {
      return "echarts-env-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  watch: {
    echarts1_option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.myChart.setOption(newVal);
          } else {
            this.myChart.setOption(oldVal);
          }
        } else {
          this.drawLine();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getStatisticalData();
    // this.drawLine();
  },
  methods: {
    // 跳转卡片对应主页
    goEnvironmentIndex() {
      //  localStorage.setItem('serviceType', 1);//li_yj on 2022-9-30警报查询
      localStorage.setItem('serviceType', 2);
      this.$router.push({
        path: "/homepage/environment/equipment",
        query: { id: this.data.id, Code: this.idCode },
      });
    },
    getStatisticalData() {
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      let siteServiceTypeId = this.data.siteServiceTypeID;
      // console.log("ID data:", "clientId:"+clientId+",siteId:"+siteId+",siteServiceTypeID:"+siteServiceTypeId);
      getdata("bivale/device/device/statisticsArray", {
        clientId,
        siteId,
        siteServiceTypeId,
      })
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.echarts1_option.series[0].data = data;
          // console.log(this.data.name + "  Environment data:", data);
        })
        .catch((err) => {
          this.loading = false;
          console.log("错了", err);
        });
    },
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      // console.log("echartsId:", this.echartsId);
      myChart.setOption(this.echarts1_option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#environment {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#environment:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>