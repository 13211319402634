<!--站点卡片--门禁卡片-->
<template>
  <div id="door" @click="goIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/door.png" />
      <span v-if="$platform.bivale=='HELC'">{{ data.name }}</span>
      <span v-else>{{ data.name }}</span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <!-- 每小时通行认证数 -->
      <div class="data-title">{{moment().format("YYYY/MM/DD")}} {{$t("sitecard.a12")}}</div>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getDoorCardData,getHistoryTotalData,getHistoryStatisticsData } from "../../../api/door";
import { EleResize } from "../../../utils/esresize";
import moment from "moment";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  name: "door",
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tag:'HELC',
      loading: false,
      option: {
        color: colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [],
          },
        ],
      },
      option2:{
        color:colors,
        dataset: [
          {
            dimensions: ['title', 'total'],
            source: []
          },
          {
            transform: {
              type: 'sort',
              config: { dimension: 'total', order: 'asc' }
            }
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          
        },
        yAxis: {
          type: 'category',
          axisLabel: { interval: 0, rotate: 0 }
        },
        grid: {
          left: '5%',
          right: '4%',
          bottom: '5%',
          top:'18%',
          containLabel: true
        },
        series: {
          type: 'bar',
          encode: { y: 'title', x: 'total' },
          datasetIndex: 1
        }
      },
      option3: {
        color: colors,
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
              let len = params.length;
              let val = 0;
              params.forEach(element => {
                val+=element.value;
              });
              let rates = [];
              if(val>0){
                params.forEach(element => {
                  rates.push(element.value/val* 100);
                });
              }else{
                params.forEach(element => {
                  rates.push(0);
                });
              }
              let name = params[0].name;
              let content = name;
              let i=0;
              params.forEach(element => {
                  content+= ' <br/>'+element.seriesName+' : '+element.value+' ('+rates[i].toFixed(2)+'%)';
                  i++;
              });

              return content;
            },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: { },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: "25%",
          containLabel: true
        },
        xAxis: [ ],
        yAxis: [{ type: 'value' } ],
        series: []
      },
    };
  },
  mounted() {
    // this.getStatisticalData()
    // this.getHistoryTotal();
    this.getHistoryStatistics();
  },
  computed: {
    echartsId() {
      return "echarts-door-" + this.idCode + '-' + Math.floor((Math.random() * 10000) + 1);
    },
  },
  methods: {
    moment,
    getHistoryStatistics(){
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      getHistoryStatisticsData({
        clientId,
        siteId,
        pageSize:10,
        serviceType:3,
        group:"DateTime",
        // group:"Date",
        // star:"2023-05-18 00:00:00",
        // end:"2023-05-31 23:59:59"
      })
        .then((res) => {
          this.loading = false;
          // console.log("res>>>",res);
          let { data } = res;
          this.option3.series = data.series;
          this.option3.legend = data.legend;
          this.option3.legend.left ='right';
          this.option3.legend.top ='10%';
          this.option3.xAxis  = data.yAxis;
          this.option3.xAxis[0].axisLabel={ show:true, interval:1, };

          this.option = this.option3;
          this.drawLine();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getHistoryTotal(){
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      getHistoryTotalData({
        clientId,
        siteId,
        pageSize:10,
        serviceType:3,
      })
        .then((res) => {
          this.loading = false;
          // console.log("res>>>",res);
          let { data } = res;
          this.option2.dataset[0].source = data;

          this.option = this.option2;
          this.drawLine();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 跳转卡片对应主页
    goIndex() {
      localStorage.setItem('serviceType', 3);
      this.$router.push({
        path: "/homepage/door/equipment",
        query: { id: this.data.id, Code: this.idCode, serviceType: 3 },
      });
    },
    // 获取卡片显示的内容数据
    getStatisticalData() {
      // console.log('卡片数据加载')
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      getDoorCardData({
        clientId,
        siteId,
      })
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log(data)
          this.option.series[0].data = data;
          this.drawLine();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 根据数据加载图表
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#door {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  margin-bottom: 20px;
  position: relative;
}
#door:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  display: flex;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>