import { render, staticRenderFns } from "./informationDistribution.vue?vue&type=template&id=2c275fbc&scoped=true"
import script from "./informationDistribution.vue?vue&type=script&lang=js"
export * from "./informationDistribution.vue?vue&type=script&lang=js"
import style0 from "./informationDistribution.vue?vue&type=style&index=0&id=2c275fbc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c275fbc",
  null
  
)

export default component.exports