<!--站点卡片--消费卡片-->
<template>
  <div id="consume" @click="goIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/consumption.png" />
      <span>{{ data.name }}</span>
    </div>
    <div
      class="main"
      v-loading="loading"
      :element-loading-text="$t('alarm.a0')"
    >
   
           <h3 class="data-title"> {{moment().subtract(1, "days").format("YYYY/MM/DD")}} {{ $t("consume.a117" ) }}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { displayData } from "../../../api/consume";
import moment from "moment";
import { EleResize } from "../../../utils/esresize";

const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  name: "consume",
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      option: {
        color:colors,
        tooltip: {
          trigger: "item",
        },
        itemStyle: {
          borderRadius: 5,
          borderColor: "#fff",
          borderWidth: 2,
        },
        series: [
          {
            name: this.$t("consume.a50"),
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "60%"],
            data: [
              { value: 0, name: this.$t("consume.a9") },
              { value: 0, name: this.$t("consume.a10") },
              { value: 0, name: this.$t("consume.a11") },
              { value: 0, name: this.$t("consume.a12") },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  // watch: {
  //   echarts1_option: {
  //     handler(newVal, oldVal) {
  //       this.drawLine();
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {
    this.getData();
  },
  computed: {
    echartsId() {
      return "echarts-consume-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  methods: {
    moment,
    // 跳转卡片对应主页
    goIndex() {
      localStorage.setItem('serviceType', 10);
      this.$router.push({
        path: "/homepage/consume/equipment",
        query: { id: this.data.id, Code: this.idCode },
      });
    },
    // 获取数据
    getData() {
      this.loading = true;
      let data = {
        siteId: this.data.id,
      };
      displayData(data)
        .then((res) => {
          // console.log("resaaaa", res);
          this.loading = false;
          let { data } = res;
          let list = [
            { value: data.breakfast, name: this.$t("consume.a9") },
            { value: data.lunch, name: this.$t("consume.a10") },
            { value: data.dinner, name: this.$t("consume.a11") },
            { value: data.superDinner, name: this.$t("consume.a12") },
          ];
          this.option.series[0].data = list;
          this.drawLine();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 根据数据加载图表
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#consume {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  flex-shrink: 0;
  cursor: pointer;
  transition: 200ms;
  position: relative;
}
#consume:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 5px;
  right: 5px;
  /* color: #474747; */
  font-size: 0.12rem;  
  font-family: 'Microsoft YaHei';
}
</style>