<!--站点卡片--通用设备管理卡片-->
<template>
  <div id="edgeEnd"  @click="goFacCommonIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <!-- <img src="../../../../public/images/moduleimg/equipment.png" /> -->
      <a-icon type="solution" style="color:#7682CE;font-size:0.26rem;margin:0.02rem 0.15rem 0.02rem 0.02rem;"/>
      <span v-if="$platform.bivale=='HELC'">{{ data.name }}</span>
      <span v-else>{{ data.siteServiceTypeName }}</span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <h3 class="data-title">{{title}}</h3>
      <!-- <a-skeleton /> -->
      <div :id="echartsId" class="chart" ></div>
    </div>
  </div>
</template>

<script>
import { getEdgeSiteCardData } from "../../../api/device";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },  
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      siteServiceTypeENname:this.data.siteServiceTypeENname,
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {left:'right'},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: "18%",
          containLabel: true
        },
        xAxis: [{
            type: 'value'
          }],
        yAxis: [
          
        ],
        series: []
      },
      title:'统计数据',
    };
  },
   computed: {
    echartsId() {
      return "echarts-edgeEnd-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  watch: {
    
  },
  mounted() {
    // console.log("this.idCode",this.idCode);
    this.getStatisticalData() ;
  },
  methods: {
     goFacCommonIndex() {
      localStorage.setItem('serviceType', 2);
      localStorage.setItem('facilityBuildName', this.data.name);
      this.$router.push({
        path: "/homepage/facility/equipment",
        query: { id: this.data.id, Code: this.idCode,siteServiceTypeENname:this.siteServiceTypeENname },
      });
    },
    getStatisticalData() {
      this.loading = true;
      let data = {
          action:1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.data.id,
          serviceType: this.data.siteServiceTypeID,
          eventType:'ENTRANCE_REGULAR',
          rpt:1,
      };
      getEdgeSiteCardData( data )
        .then((res) => {
          // console.log(res);
          this.loading = false;
          let { series,xAxis ,title} = res.data;
          this.option.series = series;
          this.option.yAxis = xAxis;
          this.option.yAxis[0].axisLabel={ show:true, interval:0, }
          this.title = title;
          // console.log("op",this.option);
          this.drawLine();
        })
        .catch((err) => {
          this.loading = false;
          console.log("错了",err);
        });
    },
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      // console.log('echartsId:',this.echartsId)
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
       let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#edgeEnd {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;

}
#edgeEnd:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  left: 5px;
  font-family: 'Microsoft YaHei';
  font-size: 0.12rem;
}
.chart{
  width: 100%;
  height:100%;
  /* height: calc(100% - 30px ); */
}
</style>