<!--站点卡片--水冷空调卡片-->
<template>
  <div id="bsdsac" @click="goIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/bsdsac.png" />
      <span>{{ data.name }}</span>
    </div>
    <div
      class="main"
      v-loading="loading"
      :element-loading-text="$t('alarm.a0')"
    >
      <h3 class="data-title">{{ $t("sitecard.a5") }}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getDeviceCardData } from "../../../api/device";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      name: "bsdsac",
      option: {
        color:colors,
        legend: {
          orient: "horizontal",
          left: 10,
          data: [],
          // data: ["运行数", "待机数", "故障数", "总数"],
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        series: [
          {
            type: "pie",
            top: "30",
            radius: "70%",
            center: ["50%", "50%"],
            data: [
              { value: 7, name: "运行数" },
              { value: 9, name: "待机数" },
              { value: 7, name: "故障数" },
              { value: 13, name: "总数" },
            ].sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: "radius",
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      },
    };
  },
  computed: {
    echartsId() {
      return "echarts-bssaac-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  // watch: {
  //   option: {
  //     deep: true,
  //     handler: function (newVal, oldVal) {
  //       let myChart = this.$echarts.init(
  //         document.getElementById(this.echartsId)
  //       );
  //       if (newVal) {
  //         myChart.setOption(newVal, true);
  //       } else {
  //         myChart.setOption(oldVal, true);
  //       }
  //       myChart.resize();
  //     },
  //   },
  // },
  mounted() {
    this.getStatisticalData();
  },
  methods: {
    getStatisticalData() {
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      let siteServiceTypeId = this.data.siteServiceTypeID;
      getDeviceCardData({
        clientId,
        siteId,
        siteServiceTypeId,
      })
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.option.series[0].data = data;
          this.drawLine();
        })
        .catch((err) => {
          console.log("错了", err);
          this.loading = false;
        });
    },
    goIndex() {
      localStorage.setItem('serviceType', 1);
      this.$router.push({
        path: "/homepage/air-conditionwater/equipment",
        query: { id: this.data.id, Code: this.idCode },
      });
    },
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#bsdsac {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#bsdsac:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>