<!--站点卡片--电梯管理卡片-->
<template>
  <div id="eleFacility"  @click="goFacCommonIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/elevator.png" />
      <span>ELE PLUS</span>
      <!-- {{ data.siteServiceTypeName }} -->
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <div>
          <h3 class="data-title">&nbsp;</h3>
          <!-- {{$t("sitecard.a6")}} -->
      </div>
      <div class="plus">
          <!-- <a-skeleton /> -->
      <!-- <div :id="echartsId" :style="{width: '100%', height: '100%'}"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getDeviceData } from "../../../api/device";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },  
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      siteServiceTypeENname:this.data.siteServiceTypeENname,
      option: {
        color:colors,
        // backgroundColor: "#2c343c",
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: 
            [  ],            
            roseType: "radius",
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      },
    };
  },
   computed: {
    echartsId() {
      return "echarts-eleFacility-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  mounted() {
    // console.log("this.idCode",this.idCode);
    // this.getStatisticalData() ;
  },
  methods: {
     goFacCommonIndex() {
      localStorage.removeItem('energykind');

      localStorage.setItem('serviceType', 17);
      this.$router.push({
        path: "/homepage/eleplus",
        query: {
          sid: this.data.id,
          cid: this.$store.getters.clientId,
          gid: this.$store.getters.sitegrId,
        },
      });
    },
    getStatisticalData() {
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      let siteServiceTypeId=this.data.siteServiceTypeID;
      // console.log("lighting par:", "clientId:"+clientId+",siteId:"+siteId+",siteServiceTypeID:"+siteServiceTypeId);
      getDeviceData( {
        clientId,
        siteId,   
        siteServiceTypeId 
      })
        .then((res) => {
          this.loading = false;
          let { data } = res;
          // console.log(res);
          this.option.series[0].data = data;
          this.drawLine();
          // console.log(this.data.name+'  Light data:',data)
        })
        .catch((err) => {
          this.loading = false;
          console.log("错了",err);
        });
    },
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      // console.log('echartsId:',this.echartsId)
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
       let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#eleFacility {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#eleFacility:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  background-color: #FFFFFF;
  position: relative;
}
.plus{
  width: 100%;
  height: 100%;
  background-image: url('../../../../public/images/eleplus/lg_img.png') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.data-title {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #474747;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>