<!--站点卡片--会议卡片-->
<template>
  <div id="conference" @click="goIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/conference.png" />
      <span>{{ data.name }} </span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getlistWithinWeekMeetingInfo } from "../../../api/user";
import { EleResize } from "../../../utils/esresize";

const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      option: {
        color:colors,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: this.$t('meeting.m1'),
            type: "pie",
            radius: "65%",
            data: [
              {
                value: 0,
                name: this.$t('meeting.m2'),//"提前结束",
                itemStyle: {
                  color: "#5470c6",
                },
              },
              {
                value: 0,
                name: this.$t('meeting.m3'),//"延时结束",
                itemStyle: {
                  color: "#fac858",
                },
              },
              {
                value: 0,
                name: this.$t('meeting.m5'),//"审核不通过",
                itemStyle: {
                  color: "#ee6666",
                },
              },
              {
                value: 0,
                name: this.$t('meeting.m4'),//"正常结束",
                itemStyle: {
                  color: "#91cc75",
                },
              },
              {
                value: 0,
                name: this.$t('meeting.m6'),//"会议取消",
                itemStyle: {
                  color: "#73c0de",
                },
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  // watch: {
  //   option: {
  //     handler(newVal, oldVal) {
  //       this.drawLine();
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {
    this.getStatisticalData();
  },
  computed: {
    echartsId() {
      return "echarts-conference-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  methods: {
    // 跳转卡片对应主页
    goIndex() {
      // console.log(this.$store.getters.sitegrId);
      localStorage.setItem('serviceType', 14);
      this.$router.push({
        path: "/homepage/conference",
        query: {
          sid: this.data.id,
          cid: this.$store.getters.clientId,
          gid: this.$store.getters.sitegrId,
        },
      });
    },
    // 获取卡片显示的内容数据
    getStatisticalData() {
      this.loading = true;
      const siteId = this.data.id;
      const data = {
        siteId,
      };
      getlistWithinWeekMeetingInfo(data)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          const list = this.option.series[0].data;
          list[0].value = data.after;
          list[1].value = data.before;
          list[2].value = data.canceled;
          list[3].value = data.noPassed;
          list[4].value = data.normal;
          this.drawLine();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 根据数据加载图表
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#conference {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#conference:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  background-color: #f2f4f7;
}
</style>