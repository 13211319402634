<!--站点卡片--多联机空调卡片-->
<template>
  <div id="vrfac" @click="goAirConditionIndex()"  :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/vrfac.png" />
      <span>{{ data.name }}</span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <h3 class="data-title">{{ $t("sitecard.a11") }}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getDeviceStatusData } from "../../../api/device";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
const colors2=['#99d080','#73c0de'];
export default {
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      siteServiceTypeENname: this.data.siteServiceTypeENname,
      option: {
        color:colors,
        legend: {
          orient: "horizontal",
          left: 10,
          data: ["运行数", "待机数", "故障数", "总数"],
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        series: [
          {
            type: "pie",
            top: "30",
            radius: "70%",
            center: ["50%", "50%"],
            data: [
              { value: 7, name: "运行数" },
              { value: 9, name: "待机数" },
              { value: 7, name: "故障数" },
              { value: 13, name: "总数" },
            ].sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: "radius",
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      },
      option3: {
        color: colors2,
        // backgroundColor:'rgba(12,90,150,0.1)',
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
              let name = params[0].name;
              let aname =  params[0].seriesName;
              let avalue =  params[0].value*1;
              let bname =  params[1].seriesName;
              let bvalue =  params[1].value*1;
              let arate = avalue/(avalue+bvalue) * 100;
              let brate = bvalue/(avalue+bvalue) * 100;
              return name + ' <br/>'+aname+' : '+avalue+' ('+arate.toFixed(2)+'%)'+ ' <br/>'+bname+' : '+bvalue+' ('+brate.toFixed(2)+'%)';
            },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: { },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: "25%",
          containLabel: true
        },
        xAxis: [ { type: 'value' } ],
        yAxis: [],
        series: []
      },
    };
  },
  computed: {
    echartsId() {
      return "echarts-vrfac-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  // watch: {
  //   option3: {
  //     deep: true,
  //     handler: function (newVal, oldVal) {
  //       let myChart = this.$echarts.init(
  //         document.getElementById(this.echartsId)
  //       );
  //       if (newVal) {
  //         myChart.setOption(newVal, true);
  //       } else {
  //         myChart.setOption(oldVal, true);
  //       }
  //       myChart.resize();
  //     },
  //   },
  // },
  mounted() {
    this.getStatisticalData();
  },
  methods: {
    getStatisticalData() {
      this.loading = true;
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      let siteServiceTypeId = this.data.siteServiceTypeID;
      getDeviceStatusData({
          clientId,
          siteId
      })
      .then((res) => {
          this.loading = false;
          let { data } = res;
          this.option3.series = data.series;
          this.option3.legend = data.legend;
          this.option3.legend.left ='right';
          this.option3.legend.top ='10%';
          this.option3.yAxis  = data.yAxis;
          this.option3.yAxis[0].axisLabel={ show:true, interval:0, };
          this.drawLine();
      })
      .catch((err) => {
        console.log("错了", err);
        this.loading = false;
      });
    },
    goAirConditionIndex() {
      // localStorage.setItem('serviceType', 1);//li_yj on 2022-9-30警报查询
      localStorage.setItem('serviceType', 2);
      this.$router.push({
        path: "/homepage/air-condition/equipment",
        query: {
          id: this.data.id,
          Code: this.idCode,
          siteServiceTypeENname: this.siteServiceTypeENname,
        },
      });
    },
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option3, true);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#vrfac {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#vrfac:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>