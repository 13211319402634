<!-- 道路测速 -- 站点卡片 -->
<template>
  <div id="velocityMeasurement" @click="goIndex()" :title="data.siteServiceTypeName" v-loading="loading" :element-loading-text="$t('velocityMeasurement.a1')">
    <div class="header">
      <img src="../../../../public/images/velocityMeasurement/velocityMeasurement.png" />
      <span>{{ data.name }}</span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <h3 class="data-title">2023/04/20 {{ $t('velocityMeasurement.a2') }}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getPublicKey, getIccToken, getDeviceList, getPassingTotal  } from '../../../api/velocityMeasurement'
import { EleResize } from '../../../utils/esresize'
import { getToken, setToken } from '../../../utils/auth'
import moment from 'moment'
const colors = ['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e']

export default {
    name:'velocityMeasurement',
    props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
      public_key: '',
      password: ''
    },
  },
    data(){
        return{
            echartsId:'nihaoapenyou',
            loading: false,
            option: {
                color: colors,
                tooltip: {
                    trigger: 'item',
                },
                itemStyle: {
                    borderRadius: 5,
                    borderColor: "#fff",
                    borderWidth: 2
                },
                series: [
                    {
                        type: 'pie',
                        top: '15px',
                        radius: ['25%','55%'],
                        data: []
                    }
                ]
            }
        }
    },
    mounted() {
        this.getVM_token()
        const timer = setInterval(()=>{
            this.getVM_token()
        },3600000)
        this.getSpeedingData()
    },
    methods:{
        moment,
        // 获取道路测速平台token方法
        getVM_token(){
            // 获取平台下发公钥
            getPublicKey()
            .then(res=>{
                const { data: { publicKey }} = res
                // 密码使用RSA加密
                const public_key = forge.pki.publicKeyFromPem('-----BEGIN PUBLIC KEY-----\n' +publicKey+'\n-----END PUBLIC KEY-----')
                const password = forge.util.encode64(public_key.encrypt('OpenAPI1a', "RSAES-PKCS1-V1_5", {
                    md: forge.md.sha1.create(),
                    mgf1: { md: forge.md.sha1.create() }
                }))
                const data = {
                    grant_type: 'password',
                    username: 'OpenAPI1',
                    password: password,
                    client_id: 'BivaleVelocityMeasurement',
                    client_secret: 'e69601a4-8e66-4a57-9ca9-7ac3ad00b1e8',
                    public_key: publicKey
                }
                // 获取
                getIccToken(data)
                .then(res=>{
                    console.log(res)
                    const { data: { access_token }} = res
                    setToken('vm-Token', access_token)
                })
                .catch(err=>{
                    console.log(err)
                })
            })
            .catch(err=>{
                console.log(err)
            })
        },
        // 获取超速数据
        getSpeedingData(){
            this.loading = true
            const data = { pageNum: 1, pageSize: 1000 }
            getDeviceList(data)
            .then(async (res)=>{
                const { data: { pageData } } = res
                const channelCodeArray = pageData.map(item => { return item.channelCode })
                const xAxisData = pageData.map(item => { return item.channelName })
                const startDate = moment().startOf("day").unix()
                const endDate = moment().endOf("day").unix()
                let arr = []
                for(let i = 0; i < channelCodeArray.length; i++){
                    const datas = { data: { startDate, endDate, channelIds:[ channelCodeArray[i] ], minSpeed: 21, maxSpeed: 220 } }
                    await getPassingTotal(datas)
                    .then( (res) => {
                        const { data } = res
                        arr.push( { name:xAxisData[i], value:data } )
                    })
                    .catch(err => { 
                        console.log(err) 
                    })
                }
                this.option.series[0].data = arr
                this.drawLine()
                this.loading = false
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 跳转卡片对应主页
        goIndex() {
            localStorage.setItem('serviceType', 23)
            this.$router.push({
                path: '/homepage/velocityMeasurement/equipment',
                query: { id: this.data.id, Code: this.idCode, serviceType: 23 }
            })
        },
        // 根据数据加载图表
        drawLine() {
            let myChart = this.$echarts.init(document.getElementById(this.echartsId))
            let resizeDiv = document.getElementById(this.echartsId)
            myChart.setOption(this.option)
            let listener = () => { myChart.resize() }
            EleResize.on(resizeDiv, listener)
        },
    }
}
</script>

<style scoped>
#velocityMeasurement{
    width: 3rem;
    height: 2.5rem;
    padding: 0.1rem;
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #ffffff;
    cursor: pointer;
    flex-shrink: 0;
    transition: 200ms;
    margin-bottom: 20px;
    position: relative;
}
#velocityMeasurement:hover{
    border: 1px solid #7682ce;
    box-shadow: 0 15px 30px #bdbcbc;
}
.header {
    width: 100%;
    height: 0.4rem;
    padding-bottom: 0.1rem;
    font-size: 0.16rem;
    display: flex;
    align-items: center;
}
.header img {
    height: 0.3rem;
    margin-right: 15px;
}
.main {
    width: 100%;
    height: 1.88rem;
    border-radius: 3px;
    display: flex;
    background-color: #f2f4f7;
    position: relative;
}
.data-title {
  position: absolute;
  top: 2px;
  right: 5px;
  font-size: 0.12rem;
  font-family: 'Microsoft YaHei';
}
</style>