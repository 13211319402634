<!--站点卡片--视频监控卡片-->
<template>
  <div id="monitoring" @click="goIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/video.png" />
      <span>{{ data.name }}</span>
    </div>
    <div
      class="main"
      v-loading="loading"
      :element-loading-text="$t('alarm.a0')"
    >
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
      <!-- 摄像机总数  @click.stop-->
      <!-- 未分组摄像机数 --><!-- 已分组摄像机数 -->
      <!-- <a-row :gutter="16">
          <a-col :span="24">
                <a-statistic :title="$t('video.viddata071')" :value="csum" style="margin: 5px;  padding: 5px;  background-color: #ffffff;" />
            
          </a-col>
            </a-row>
              <a-row :gutter="16">
          <a-col :span="24">
               
                <a-statistic :title="$t('video.viddata073')" :value="cunre" style="margin: 5px;  padding: 5px;  background-color: #ffffff;" />
            
            </a-col>
              </a-row>
              <a-row :gutter="16">
              <a-col :span="24">
                
                <a-statistic :title="$t('video.viddata072')" :value="cre" style="margin: 5px;  padding: 5px;  background-color: #ffffff;" />
            
              </a-col>
        </a-row> -->
    </div>
  </div>
</template>

<script>
import { getVideoCardData } from "../../../api/video";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      csum: 0,
      cunre: 0,
      cre: 0,
      veopt: {
        color:colors,
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        series: [],
      },
      serie1: {
        name: this.$t("video.viddata065"),
        type: "pie",
        selectedMode: "single",
        radius: [0, "30%"],
        label: {
          position: "inner",
          fontSize: 10,
        },
        labelLine: {
          show: false,
        },
        data: [],
      },
      serie2: {
        name: this.$t("video.viddata065"),
        type: "pie",
        radius: ["45%", "60%"],
        data: [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    echartsId() {
      return "echarts-pass" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  methods: {
    goIndex() {
      localStorage.setItem('serviceType', 19);
      this.$router.push({
        path: "/homepage/monitoring/live",
        query: { id: this.data.id, Code: this.idCode },
      });
    },
    // 获取显示数据
    getData() {
      let siteId = this.data.id;
      this.loading = true;
      getVideoCardData(siteId)
        .then((res) => {
          if (res.errorCode == "00") {
            let series = new Array();

            if(res.data.videoCardData){
                this.serie2.data = res.data.videoCardData;
                series.push(this.serie2);
            }
            this.veopt.series = series;
            this.drawPie();
          } else {
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 根据数据加载图表
    drawPie() {
      let videoChart = this.$echarts.init(
        document.getElementById(this.echartsId)
      );
      let resizeDiv = document.getElementById(this.echartsId);
      videoChart.setOption(this.veopt);
      let listener = () => {
        videoChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#monitoring {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #ffffff;
  flex-shrink: 0;
  cursor: pointer;
  transition: 200ms;
  margin-bottom: 20px;
  position: relative;
}
#monitoring:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  padding-bottom: 10px;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  background-color: #f2f4f7;
}
</style>