<!--站点卡片--水能卡片-->
<template>
  <div id="waterenergy" @click="goIndex()"  :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/water.png" />
      <span>{{ data.name }}</span>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <h3 class="data-title">{{ $t("sitecard.a2") }}</h3>
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getEnergyData, getEnergyDailyData } from "../../../api/energy";
import { EleResize } from "../../../utils/esresize";

export default {
  name: "waterenergy",
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      kind: 2,
      option: {
        color: ["#73c0de"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          name: "(L/月)",
          type: "value",
        },
        series: {
          name: "用水量(L/月)",
          type: "bar",
          data: [10, 52, 200, 334, 390, 930, 220],
        },
      },
    };
  },
  // watch: {
  //   option: {
  //     handler(newVal, oldVal) {
  //       this.drawLine();
  //     },
  //     deep: true,
  //   },
  // },
  computed: {
    echartsId() {
      return "echarts-water-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  mounted() {
    this.getData();
    // this.drawLine();
  },
  methods: {
    // 点击卡片跳转至能源主页
    goIndex() {
      localStorage.setItem('serviceType', 3);
      localStorage.setItem('energykind', 9);
      localStorage.removeItem('search-params');
      this.$router.push({
        path: "/homepage/energy/Ichnography",
        query: { id: this.data.id, Code: this.idCode, kind: 9 },
      });
    },
    // 获取显示数据
    getData(){
      let siteId = this.data.id;
      this.loading = true;

      if (this.kind == 1) {
        getEnergyData(siteId)
          .then((res) => {
            // console.log("GetEnergyData:", res);
            this.setOptionData(res.data);
            this.drawLine();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else if (this.kind == 2) {
        getEnergyDailyData(siteId)
          .then((res) => {
            // console.log("GetEnergyDailyData:", res);
            this.setOptionData(res.data);
            this.drawLine();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    setOptionData(data) {
      let name = "(m³" + data.unit + ")";
      this.option.yAxis.name = name;
      this.option.xAxis.data = data.categories;
      this.option.tooltip.formatter = "{a} <br/>{b}: {c} m³";
      this.option.series.data = data.data;
      this.option.series.name = this.$t("energy.enedata377") + name;
    },
    // 调用echarts
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#waterenergy {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#waterenergy:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  background-color: #f2f4f7;
  position: relative;
}
.data-title {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #474747;
  font-size: 0.12rem;
}
</style>