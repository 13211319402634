<!--站点卡片--电梯卡片-->
<template>
  <div id="elevator" @click="goIndex()" :title="data.siteServiceTypeName">
    <div class="header">
      <img src="../../../../public/images/moduleimg/elevator.png" />
      <span v-if="$platform.bivale=='HELC'">{{ data.name }}</span>
      <span v-else>{{ data.name }}</span>
    </div>
    <div class="main">
      <div :id="echartsId" :style="{ width: '100%', height: '100%' }"></div>
    </div>
  </div>
</template>

<script>
import { getElevatorCardData } from "../../../api/user";
import { EleResize } from "../../../utils/esresize";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
export default {
  name: "elevator",
  props: {
    data: {
      type: Object,
    },
    idCode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tag:'HELC',
      echarts1_option: {
        color:colors,
        //  [
        //   "#3ba272",
        //   "#fac858",
        //   "#ee6666",
        //   "#73c0de",
        //   "#fc8452",
        //   "#91cc75",
        //   "#9a60b4",
        //   "#ea7ccc",
        // ],
        series: [
          {
            top: 0,
            name: "电梯模块",
            type: "pie",
            radius: ["25%", "50%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 15,
            },
            label: {
              formatter: "{b|{b}:{c}}\n {per|{d}%}",
              // backgroundColor: "#eee",
              borderColor: "#F2f5f7",
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                b: {
                  fontSize: 12,
                  lineHeight: 25,
                  padding: [0, 10, 0, 10],
                },
                per: {
                  color: "#eee",
                  align: "center",
                  backgroundColor: "#7682ce",
                  lineHeight: 25,
                  padding: [3, 4],
                  borderRadius: 2,
                },
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    echarts1_option: {
      handler(newVal, oldVal) {
        this.drawLine();
      },
      deep: true,
    },
  },
  mounted() {
    this.getStatisticalData();
    // this.drawLine();
  },
  computed: {
    echartsId() {
      return "echarts-ele-" + this.idCode + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
  methods: {
    goIndex1() {
      localStorage.setItem('serviceType', 9);
      this.$store.commit("menu/SITE_MENU", this.idCode);
      this.$router.push({
        path: "/homepage/elevator/equipment",
        query: { id: this.data.id },
      });
    },
    goIndex() {
      localStorage.setItem('serviceType', 9);
      this.$router.push({
        path: "/homepage/elevator/equipment",
        query: { id: this.data.id, Code: this.idCode },
      });
    },
    // 获取卡片显示的内容数据
    getStatisticalData() {
      let clientId = this.$store.getters.clientId;
      let siteId = this.data.id;
      getElevatorCardData({
        siteId,
      })
        .then((res) => {
          let { data } = res;
          this.echarts1_option.series[0].data = [
            { value: data.normal, name: "在线" },
            { value: data.disconnect, name: "离线" },
            { value: data.error, name: "异常" },
          ];
        })
        .catch((err) => {
          console.log("电梯模块报错", err);
        });
    },
    // 根据数据加载图表
    drawLine() {
      console.log("echartsId:", this.echartsId);
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let resizeDiv = document.getElementById(this.echartsId);
      myChart.setOption(this.echarts1_option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
};
</script>

<style scoped>
#elevator {
  width: 3rem;
  height: 2.5rem;
  padding: 0.1rem;
  background-color: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  flex-shrink: 0;
  transition: 200ms;
  position: relative;
}
#elevator:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.header {
  width: 100%;
  height: 0.4rem;
  padding-bottom: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}
.header img {
  height: 0.3rem;
  margin-right: 15px;
}
.main {
  width: 100%;
  height: 1.88rem;
  border-radius: 3px;
  background-color: #f2f4f7;
  line-height: 250px;
}
</style>